<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Фид'" :title="'Список Фидов'">
      <div class="list__buttons">
        <MainButton @click.native="handleCreate()">Create</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div v-if="feedsList.length">
          <CustomTable
            v-if="feedsList.length"
            :data="feedsList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handle-delete="handleDelete"
            :handle-logs="handleOpenLogsModal"
            :handle-copy="handleDetail"
            :handleFilter="handleFilter"
            :direction="directionSort"
            :order="orderSort"
            :name="'feeds'"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="feeds.lastPage"
              :page="page"
              :urlList="'FEED_LIST'"
              :totalItem="feeds.total"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Feed found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Feeds - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteRow" :button-cancel="true">DELETE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="logsModal" :on-close="handleCloseLogsModal" :modal-center="true">
      <div class="logs-modal">
        <div class="logs-modal__title">Logs List</div>
        <div v-if="logsModalArray.length > 0" class="logs-modal__list">
          <div v-for="(log, index) in logsModalArray" :key="index" class="logs-modal__list-item">
            <div class="logs-modal__list-header">
              <div>{{ formatDate(log.elasticCreatedAt) }}</div>
              <div>{{ log.logEvent }}</div>
              <div>{{ log.logType }}</div>
            </div>
            <div><b>Processed Flats Count:</b> {{ log.processedFlatsCount }}</div>
            <div><b>Message:</b> {{ log.message }}</div>
            <div class="logs-modal__error" v-if="log.errorMessage">
              <b>Error Message:</b> {{ log.errorMessage }}
            </div>
          </div>
        </div>
        <div class="logs-modal__no-logs" v-else>No Logs</div>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Feeds {{ deleteRow.name }} successfully deleted.</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import feedApi from "~/api/feed";
import moment from "moment";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";

export default {
  name: "FeedsList",
  metaInfo: {
    title: "Feeds List",
  },
  data() {
    return {
      logsModal: false,
      logsModalArray: [],
      deleteModal: false,
      deleteRow: null,
      deleteModalSuccess: false,
      directionSort: true,
      orderSort: "id",
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "link",
            to: "Link",
          },
          {
            from: "statusName",
            to: "Status",
          },
        ],
        customOrder: ["id", "link", "statusName", "null"],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
  },
  mounted() {
    this.getList();
  },
  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  computed: {
    ...mapState("feed", ["error", "loadingProcess", "feeds", "feedsList"]),
    page() {
      return +this.$route.params.page;
    },
    getMaxPage() {
      return Math.ceil(this.feeds.pagination.allCount / 20);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    getList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url =
          url +
          `&relations[0]=status&relations[1]=logs&sortField=${this.orderSort}&sortDest=${
            this.directionSort ? "asc" : "desc"
          }`;
      }
      this.$store.dispatch("feed/getFeedsList", url);
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handleEdit(row) {
      this.$router.push({
        path: ROUTE.FEED_EDIT.replace(":id", `${row.id}`),
      });
    },
    handleDetail(row) {
      this.$router.push({
        path: ROUTE.FLAT_LIST.replace(":feedId", `${row.id}`).replace(":page", "1"),
      });
    },
    handleCreate() {
      this.$router.push({
        path: ROUTE.FEED_CREATE,
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleDeleteRow() {
      const url = `/${this.deleteRow.id}`;
      this.$store.commit("feed/setLoading", true);
      feedApi
        .deleteFeed(url)
        .then((res) => {
          this.$store.commit("feed/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("feed/setLoading", false);
          console.log(error);
        });
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal() {
      this.error = null;
    },
    handleCloseLogsModal() {
      this.logsModal = false;
      this.logsModalArray = [];
    },
    handleOpenLogsModal(row) {
      this.logsModal = true;
      this.logsModalArray = this.feedsList.find((item) => item.id === row.id).logs;
      console.log(this.logsModalArray);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.custom-table__header,
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 2fr 1fr 1fr !important;
}

.logs-modal {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &__no-logs {
    font-size: 30px;
    font-weight: 700;
    color: grey;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 15px;
  }

  &__list {
    width: 100%;
  }

  &__list-item {
    width: 100%;
    font-size: 18px;
    padding: 15px 15px 15px;
    background: rgb(238, 238, 238);
    border-radius: 10px;
    margin-bottom: 10px;

    div {
      padding-right: 30px;
    }
  }

  &__list-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  &__error {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* Ограничение до 4 строк */
    overflow: hidden;
  }
}
</style>
